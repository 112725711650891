<template>
  <div class="home">
    <div class="menu">
      <div class="header">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#000000"
          active-text-color="#FF8100"
          @select="handleSelect"
        >
          <el-menu-item index="1">系统介绍</el-menu-item>
          <el-menu-item index="2">应用场景</el-menu-item>
          <el-menu-item index="3">产品特色</el-menu-item>
          <el-menu-item index="4">企业愿景</el-menu-item>
          <div class="dl" @click="toLogin('https://sxb.zfire.top')">
            登录平台>
          </div>
          <img src="@/assets/logo.png" alt="" />
        </el-menu>
      </div>
      <div class="backdrop flex">
        <div class="left"></div>
        <div class="banner">
          <img src="@/assets/banner.png" alt="" />
          <div class="contnet">
            <div class="t1">售修宝</div>
            <div class="t1">家电售后业务管理平台</div>
            <div class="t2">专业为家电售后服务打造，满足各种运营管理需求</div>
            <div class="btn" @click="toBuy()">立即购买 ></div>
          </div>
        </div>
        <div class="right"></div>
      </div>
      <div class="main">
        <div id="mode1" class="mode" ref="content">
          <div class="title">赋能中小企业三大业务领域</div>
          <div class="text">
            基于云计算架构设计，利用互联网技术改变传统管理模式，专注解决企业日常经营管理，让企业经营管理更高效。<br />适用于各类中小型售后企业、门店等用户。
          </div>
          <div class="flex">
            <div class="item">
              <img src="@/assets/img1.png" alt="" />
              <div style="padding: 36px 28px">
                <div class="title2">分销商城</div>
                <div class="text2">
                  满足家政、保洁、清洗、安装、维修等行业线上销售、到家服务、运营等需求
                </div>
              </div>
            </div>
            <div class="item">
              <img src="@/assets/img2.png" alt="" />
              <div style="padding: 36px 28px">
                <div class="title2">进销存管理</div>
                <div class="text2">
                  满足售后行业对辅材、配件的采购、销售、及库存管理、销售结算等需求
                </div>
              </div>
            </div>
            <div class="item">
              <img src="@/assets/img3.png" alt="" />
              <div style="padding: 36px 28px">
                <div class="title2">工单管理</div>
                <div class="text2">
                  满足售后行业服务工单的派工、跟进、回访及用户评价管理需求
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div class="btn" @click="toBuy()">立即购买 ></div>
          </div>
        </div>
        <div style="background: #eff1f5">
          <div id="mode2" class="mode" ref="content">
            <div class="title" style="margin-bottom: 48px">
              你是否有过这样的困惑？
            </div>
            <div class="flex">
              <div class="item">
                <img src="@/assets/img4.png" alt="" />
                <div style="padding: 36px 28px">
                  <div class="text2">
                    服务人员流动大，工单服务不及时，引起客户投诉。
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/img5.png" alt="" />
                <div style="padding: 36px 28px">
                  <div class="text2">
                    辅材配件管理粗放，无法实时监控，极易造成辅材配件损失。
                  </div>
                </div>
              </div>
              <div class="item">
                <img src="@/assets/img6.png" alt="" />
                <div style="padding: 36px 28px">
                  <div class="text2">
                    财务人员不足，手工记账耗时费力又易造在错漏。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="mode3" class="mode" ref="content">
          <div class="title" style="margin-bottom: 48px">产品与服务特色</div>
          <div class="text">
            满足行业需求的功能是基础，强而有力的售后服务是支撑你商业成功的关键
          </div>
          <div class="flex">
            <img src="@/assets/img7.png" alt="" />
            <div class="content1">
              <div class="title2">经过市场验证的功能</div>
              <div class="text2">
                专注家电售后服务领域，经过真实用户验证并不断迭代优化创新，整合提炼行业共性功能，满足各企业不同管理模式。
              </div>
              <div class="btn" @click="goAnchor('#bottom')">了解更多 ></div>
            </div>
          </div>
          <div class="flex">
            <div class="content2">
              <div class="title2">简单易用的操作</div>
              <div class="text2">
                去除多余繁杂的业务功能，简化操作流程，使系统更轻量、易用，更适合小规模企业。
              </div>
              <div class="btn" @click="goAnchor('#bottom')">了解更多 ></div>
            </div>
            <img src="@/assets/img8.png" alt="" />
          </div>
          <div class="flex">
            <img src="@/assets/img9.png" alt="" />
            <div class="content1">
              <div class="title2">拥抱变更和创新</div>
              <div class="text2">
                拥抱变化、勇于创新、包容失败，我们不断提取迭代行业共性需求，不收取定制开发费，确保运营管理方法行业领先。
              </div>
              <div class="btn" @click="goAnchor('#bottom')">了解更多 ></div>
            </div>
          </div>
        </div>
        <div style="background: #eff1f5">
          <div id="mode4" class="mode" ref="content">
            <div class="title" style="margin-bottom: 48px">
              我们的使命是助力客户商业成功
            </div>
            <div class="text">
              客户商业成功是售修宝成功的基础，我们所有工作围绕帮助客户商业成功展开
            </div>
            <div class="flex">
              <div class="item1 width2 padd">
                <div class="title2">提升销售业绩</div>
                <div class="text2">
                  售修宝的线上分销商城可以助力企业进行线上销售，集商品、服务于一体，扩展企业的销售渠道，提示销售业绩。
                </div>
                <div @click="goAnchor('#bottom')" class="more">了解更多 ></div>
              </div>
              <img class="width1" src="@/assets/img10.png" alt="" />
            </div>
            <div class="flex" style="margin: 30px 0">
              <img class="width2" src="@/assets/img11.png" alt="" />
              <div class="item1 width1 padd">
                <div class="title2">简单易用的操作</div>
                <div class="text2">
                  去除多余繁杂的业务功能，简化操作流程，使系统更轻量、易用，更适合小规模企业。
                </div>
                <div @click="goAnchor('#bottom')" class="more">了解更多 ></div>
              </div>
            </div>
            <div class="flex">
              <div class="item1 width2 padd">
                <div class="title2">拥抱变更和创新</div>
                <div class="text2">
                  拥抱变化、勇于创新、包容失败，我们不断提取迭代行业共性需求，不收取定制开发费，确保运营管理方法行业领先。
                </div>
                <div @click="goAnchor('#bottom')" class="more">了解更多 ></div>
              </div>
              <img class="width1" src="@/assets/img12.png" alt="" />
            </div>
          </div>
        </div>
        <div id="mode5">
          <div class="flex">
            <div class="item1">
              <div class="title">
                联系售修宝售后服务解决方案专家，探讨您的项目
              </div>
              <div @click="goAnchor('#bottom')" class="btn">立即咨询 ></div>
            </div>
            <img src="@/assets/img13.png" alt="" />
          </div>
        </div>
      </div>
      <div style="background-color: #131415">
        <div class="footer" id="bottom">
          <div class="flex_asb">
            <div class="item1">
              <div class="name">广州众炬科技有限公司</div>
              <div class="text">
                地址：广州市天河区华景新城华景园区B栋-东梯603
              </div>
              <div class="text">电话：13430387965</div>
              <div class="text">邮箱：admin@zfire.top</div>
            </div>
            <div class="flex">
              <div class="ewm">
                <img src="@/assets/m1.png" alt="" />
                <div>业务咨询客服1</div>
              </div>
              <div class="ewm">
                <img src="@/assets/m2.png" alt="" />
                <div>业务咨询客服2</div>
              </div>
            </div>
          </div>
          <div class="flex_asb beian">
            <div>©2020-2024 广州众炬科技有限公司 版权所有</div>
            <div class="flex">
              <div
                @click="toLogin('https://beian.miit.gov.cn/#/Integrated/index')"
                style="cursor: pointer"
              >
                粤ICP备 2020090308 号
              </div>
              <div
                @click="toLogin('https://beian.mps.gov.cn/#/query/webSearch')"
                style="margin-left: 40px; cursor: pointer"
              >
                粤公网安备 44010602008477 号
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      activeIndex: '1',
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // 监听滚动事件
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll); // 移除滚动事件监听器
  },
  methods: {
    handleSelect(key, keyPath) {
      this.goAnchor('#mode' + key);
    },
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; // 获取滚动条位置
      const navContents = document.querySelectorAll('.mode');
      const offsetTopArr = [];
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop - 200);
      });
      let navIndex = 0;
      console.log(scrollTop, offsetTopArr);
      for (let n = 0; n < offsetTopArr.length; n++) {
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n + 1;
          this.activeIndex = String(navIndex);
          // 滑动底部选中最后一个选项
          // if (this.$refs.content_wrapper.scrollHeight - this.$refs.content_wrapper.scrollTop <= this.$refs.content_wrapper.clientHeight + 50) {
          // 	this.activeIndex = String(offsetTopArr.length - 1)
          // }
        }
      }
    },
    goAnchor(selector) {
      /*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth',
      });
    },
    toLogin(url) {
      window.open(url);
    },
    toBuy() {
      this.$router.push('buy');
    },
  },
};
</script>
<style>
body {
  margin: 0 !important;
}
</style>
<style scoped lang="scss">
.home {
  padding-top: 82px;
  box-sizing: border-box;
  background-color: #ffffff;
}
.flex {
  display: flex;
  justify-content: center;
}
.flex_asb {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1700px) {
  .header {
    width: 100%;
    height: 82px;
    background-color: #ffffff;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    // margin-left: -960px;
    display: flex;
    justify-content: center;
    .el-menu-demo {
      min-width: 1080px;
      width: 1700px;
      max-width: 1700px;
      display: flex;
      justify-content: flex-end;
      padding: 10px 360px 0 160px;
      box-sizing: border-box;
    }
    .el-menu-item {
      font-size: 18px;
    }
    img {
      width: 90px;
      height: 38px;
      position: absolute;
      left: 150px;
      top: 22px;
      z-index: 100;
    }
    .dl {
      width: 126px;
      height: 34px;
      position: absolute;
      right: 158px;
      top: 22px;
      z-index: 100;
      text-align: center;
      line-height: 34px;
      border-radius: 17px;
      color: #ffffff;
      background-color: #2256ff;
      cursor: pointer;
    }
  }
  .backdrop {
    .left {
      width: 50%;
      height: 460px;
      background-color: #bcc8f8; //e4e8f4
      position: absolute;
      left: 0;
      z-index: 9;
    }
    .right {
      width: 50%;
      height: 460px;
      background-color: #e4e8f4;
      position: absolute;
      right: 0;
      z-index: 9;
    }
    .banner {
      min-width: 1080px;
      max-width: 1700px;
      height: 460px;
      margin: 0 auto;
      position: relative;
      z-index: 10;
      img {
        width: 100%;
        height: 100%;
      }
      .contnet {
        position: absolute;
        bottom: 10%;
        left: 7%;
        text-align: left;
        .t1 {
          font-size: 48px;
          font-weight: bold;
          color: #000000;
        }
        .t2 {
          font-size: 22px;
          color: #4e4e4f;
          margin: 50px 0;
        }
        .btn {
          width: 180px;
          height: 46px;
          background: #ff8100;
          border-radius: 23px 23px 23px 0px;
          font-size: 24px;
          color: #ffffff;
          text-align: center;
          line-height: 46px;
          cursor: pointer;
        }
      }
    }
  }
  .main {
    .mode {
      min-width: 1080px;
      max-width: 1700px;
      margin: 0 auto;
      background: #ffffff;
      padding-top: 50px;
      box-sizing: border-box;
      .title {
        font-size: 38px;
        font-weight: bold;
        color: #000000;
      }
      .text {
        font-size: 22px;
        color: #4e4e4f;
        margin: 28px 0;
      }
      .item {
        width: 400px;
        margin-right: 25px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: -1px 0px 10px 1px rgba(123, 123, 123, 0.18);
        text-align: left;
        img {
          width: 100%;
          // height: 330px;
        }
      }
      .btn {
        width: 180px;
        height: 46px;
        background: #ff8100;
        border-radius: 23px;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        line-height: 46px;
        cursor: pointer;
      }
    }
    #mode1 {
      .title2 {
        font-size: 22px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 10px;
      }
      .text2 {
        font-size: 20px;
        color: #4e4e4f;
        line-height: 32px;
      }
    }
    #mode2 {
      background: #eff1f5;
      padding-bottom: 62px;
      box-sizing: border-box;
      :last-child {
        margin-right: 0;
      }
    }
    #mode3 {
      // padding: 0 145px;
      padding-top: 53px;
      box-sizing: border-box;
      img {
        width: 560px;
        height: 510px;
      }
      .title2 {
        font-size: 28px;
        color: #000000;
        font-weight: bold;
        margin-top: 58px;
      }
      .text2 {
        font-size: 20px;
        color: #4e4e4f;
        line-height: 32px;
      }
      .content1 {
        width: 600px;
        margin-left: 120px;
        text-align: left;
      }
      .content2 {
        width: 600px;
        margin-right: 120px;
        text-align: left;
      }
      .btn {
        width: 180px;
        height: 46px;
        background: #ff8100;
        border-radius: 23px 23px 23px 0px;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        line-height: 46px;
        cursor: pointer;
        margin-top: 52px;
      }
    }
    #mode4 {
      background: #eff1f5;
      padding-bottom: 74px;
      box-sizing: border-box;
      .item1 {
        height: 286px;
        text-align: left;
        background-color: #ffffff;
        box-sizing: border-box;
      }
      .width1 {
        width: 634px;
      }
      .width2 {
        width: 626px;
      }
      .title2 {
        font-size: 28px;
        color: #000000;
        font-weight: bold;
      }
      .text2 {
        font-size: 20px;
        color: #4e4e4f;
        line-height: 32px;
        margin-top: 30px;
        margin-bottom: 46px;
      }
      .padd {
        padding: 40px 35px;
        box-sizing: border-box;
      }
      img {
        width: 626px;
        height: 286px;
      }
      .more {
        color: #ff8100;
        font-size: 22px;
        cursor: pointer;
      }
    }
    #mode5 {
      min-width: 1080px;
      max-width: 1700px;
      margin: 0 auto;
      padding: 0 137px;
      background-color: #ffffff;
      box-sizing: border-box;
      text-align: left;
      .flex {
        justify-content: space-between;
      }
      .title {
        font-size: 36px;
        font-weight: bold;
        color: #000000;
        margin-top: 68px;
        margin-bottom: 34px;
      }
      .btn {
        width: 180px;
        height: 46px;
        background: #ff8100;
        border-radius: 23px 23px 23px 0px;
        font-size: 24px;
        color: #ffffff;
        text-align: center;
        line-height: 46px;
        cursor: pointer;
      }
      img {
        width: 830px;
        height: 306px;
      }
    }
  }
  .footer {
    min-width: 1080px;
    max-width: 1700px;
    height: 345px;
    margin: 0 auto;
    background-color: #131415;
    padding: 57px 140px 27px;
    color: #ffffff;
    text-align: left;
    box-sizing: border-box;
    .item1 {
      .name {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .text {
        font-size: 16px;
        color: #b0b4bb;
        line-height: 34px;
        letter-spacing: 2.8px;
      }
    }
    .ewm {
      text-align: center;
      margin-left: 67px;
      img {
        width: 140px;
        height: 140px;
      }
    }
    .beian {
      margin-top: 60px;
      color: #b0b4bb;
      font-size: 14px;
    }
  }
}
@media screen and (min-width: 1700px) {
  .header {
    width: 100%;
    height: 82px;
    background-color: #ffffff;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    // margin-left: -960px;
    display: flex;
    justify-content: center;
    .el-menu-demo {
      min-width: 1080px;
      width: 1920px;
      max-width: 1920px;
      display: flex;
      justify-content: flex-end;
      padding: 10px 360px 0 160px;
      box-sizing: border-box;
    }
    .el-menu-item {
      font-size: 20px;
    }
    img {
      width: 110px;
      height: 48px;
      position: absolute;
      left: 160px;
      top: 17px;
      z-index: 100;
    }
    .dl {
      width: 146px;
      height: 44px;
      position: absolute;
      right: 168px;
      top: 17px;
      z-index: 100;
      text-align: center;
      line-height: 44px;
      border-radius: 22px;
      color: #ffffff;
      background-color: #2256ff;
      cursor: pointer;
    }
  }
  .backdrop {
    .left {
      width: 50%;
      height: 660px;
      background-color: #bcc8f8; //e4e8f4
      position: absolute;
      left: 0;
      z-index: 9;
    }
    .right {
      width: 50%;
      height: 660px;
      background-color: #e4e8f4;
      position: absolute;
      right: 0;
      z-index: 9;
    }
    .banner {
      min-width: 1080px;
      max-width: 1920px;
      height: 660px;
      margin: 0 auto;
      position: relative;
      z-index: 10;
      img {
        width: 100%;
        height: 100%;
      }
      .contnet {
        position: absolute;
        bottom: 10%;
        left: 10%;
        text-align: left;
        .t1 {
          font-size: 75px;
          font-weight: bold;
          color: #000000;
        }
        .t2 {
          font-size: 24px;
          color: #4e4e4f;
          margin: 50px 0;
        }
        .btn {
          width: 220px;
          height: 66px;
          background: #ff8100;
          border-radius: 33px 33px 33px 0px;
          font-size: 30px;
          color: #ffffff;
          text-align: center;
          line-height: 66px;
          cursor: pointer;
        }
      }
    }
  }
  .main {
    .mode {
      min-width: 1080px;
      max-width: 1920px;
      margin: 0 auto;
      background: #ffffff;
      padding-top: 90px;
      box-sizing: border-box;
      .title {
        font-size: 56px;
        font-weight: bold;
        color: #000000;
      }
      .text {
        font-size: 24px;
        color: #4e4e4f;
        margin: 28px 0;
      }
      .item {
        width: 520px;
        margin-right: 25px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: -1px 0px 10px 1px rgba(123, 123, 123, 0.18);
        text-align: left;
        img {
          width: 100%;
          // height: 330px;
        }
      }
      .btn {
        width: 220px;
        height: 66px;
        background: #ff8100;
        border-radius: 33px;
        font-size: 30px;
        color: #ffffff;
        text-align: center;
        line-height: 66px;
        cursor: pointer;
      }
    }
    #mode1 {
      .title2 {
        font-size: 26px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 20px;
      }
      .text2 {
        font-size: 24px;
        color: #4e4e4f;
        line-height: 36px;
      }
    }
    #mode2 {
      background: #eff1f5;
      padding-bottom: 92px;
      box-sizing: border-box;
      :last-child {
        margin-right: 0;
      }
    }
    #mode3 {
      padding: 0 145px;
      padding-top: 83px;
      box-sizing: border-box;
      img {
        width: 660px;
        height: 610px;
      }
      .title2 {
        font-size: 34px;
        color: #000000;
        font-weight: bold;
        margin-top: 78px;
      }
      .text2 {
        font-size: 24px;
        color: #4e4e4f;
        line-height: 36px;
      }
      .content1 {
        margin-left: 160px;
        text-align: left;
      }
      .content2 {
        margin-right: 160px;
        text-align: left;
      }
      .btn {
        width: 220px;
        height: 66px;
        background: #ff8100;
        border-radius: 33px 33px 33px 0px;
        font-size: 30px;
        color: #ffffff;
        text-align: center;
        line-height: 66px;
        cursor: pointer;
        margin-top: 52px;
      }
    }
    #mode4 {
      background: #eff1f5;
      padding-bottom: 94px;
      box-sizing: border-box;
      .item1 {
        height: 386px;
        text-align: left;
        background-color: #ffffff;
        box-sizing: border-box;
      }
      .width1 {
        width: 774px;
      }
      .width2 {
        width: 866px;
      }
      .title2 {
        font-size: 34px;
        color: #000000;
        font-weight: bold;
      }
      .text2 {
        font-size: 24px;
        color: #4e4e4f;
        line-height: 36px;
        margin-top: 30px;
        margin-bottom: 76px;
      }
      .padd {
        padding: 80px 35px;
        box-sizing: border-box;
      }
      img {
        width: 866px;
        height: 386px;
      }
      .more {
        color: #ff8100;
        font-size: 22px;
        cursor: pointer;
      }
    }
    #mode5 {
      min-width: 1080px;
      max-width: 1920px;
      margin: 0 auto;
      padding: 0 137px;
      background-color: #ffffff;
      box-sizing: border-box;
      text-align: left;
      .flex {
        justify-content: space-between;
      }
      .title {
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        margin-top: 68px;
        margin-bottom: 34px;
      }
      .btn {
        width: 220px;
        height: 66px;
        background: #ff8100;
        border-radius: 33px 33px 33px 0px;
        font-size: 30px;
        color: #ffffff;
        text-align: center;
        line-height: 66px;
        cursor: pointer;
      }
      img {
        width: 830px;
        height: 306px;
      }
    }
  }
  .footer {
    min-width: 1080px;
    max-width: 1920px;
    height: 415px;
    margin: 0 auto;
    background-color: #131415;
    padding: 87px 170px 47px;
    color: #ffffff;
    text-align: left;
    box-sizing: border-box;
    .item1 {
      .name {
        font-size: 28px;
        margin-bottom: 20px;
      }
      .text {
        font-size: 24px;
        color: #b0b4bb;
        line-height: 38px;
        letter-spacing: 2.8px;
      }
    }
    .ewm {
      text-align: center;
      margin-left: 87px;
      img {
        width: 160px;
        height: 160px;
      }
    }
    .beian {
      margin-top: 60px;
      color: #b0b4bb;
      font-size: 20px;
    }
  }
}
</style>
